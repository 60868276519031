
import { defineStore } from 'pinia';
import { reactive } from 'vue';
import mushroom from "cem-primary-api";
import MethodService from "../service/MethodService";
import Data from "../views/don-vi-hanh-chinh/dataFormHanhChinh";

const tableRules = reactive(MethodService.copyObject(Data.tableRules));

export const DVHCStore = defineStore({
  id: 'dvhc',
  state: () => ({
    provinceData: [], // lấy dữ liệu tỉnh cho vào select
    districtDataArray: [],
    tableRulesDistrict: tableRules,
    idProvince: '',
  }),
  getters: {
    // code đổi data ở đây nhưng mà chưa biết viết kiểu gì
  },
  actions: {
    // lấy danh sách tỉnh
    async getProvince() {
      try {
        const response = await mushroom.province.listAsync({ limit: 100 }, { cacheAge: 1 });
        console.log("Các province thỏa mãn: %o, info: %o", response);
        if (response?.result.length > 0) {
          this.provinceData = [];
          response.result.forEach(province => {
            this.provinceData.push({
              value: province.id,
              label: province.type + " " + province.name,
              center: [province.center.lng, province.center.lat],
            });
          });
        }
      }
      catch (e) {
        console.error("Có lỗi: %o", e);
      }
    },

    // lấy danh sách huyện
    async getDistrict() {
      let dataFilter = {
        filters: tableRules.filters,
        fields: "id,disabled,code,name,provinceId,index,type,center",
        limit: 1000,
        offset: tableRules.offset,
        sort: tableRules.sort,
      };
      try {
        const response = await mushroom.district.listAsync(dataFilter, { cacheAge: 1 });
        console.log("Các district thỏa mãn: %o, info: %o", response);
        if (response?.result.length > 0) {
          response.result.forEach(item => {
            item.fullNameProvince = item.type + " " + item.name
          })
          this.districtDataArray = response.result
          tableRules.total = response.meta.total;
          tableRules.page = response.meta.offset / response.meta.limit + 1;
        }
      }
      catch (e) {
        console.error("Có lỗi: %o", e);
      }
    },

    // lấy danh sách huyện theo mã tỉnh
    // code here
    async getDistrictById(id) {
      console.log("id", id);
      try {
        const response = await mushroom.district.listAsync({
          provinceId: id
        });
        console.log("Thông tin district trả về: %o", response);
      }
      catch (e) {
        console.error("Có lỗi: %o", e);
      }
    },

    async getProvinceById(id) {
      if (!id) {
        return;
      }
      try {
        console.log('id huyện trong store: ', id)
        const response = await mushroom.district.listAsync({
          filters: `id=${id}`
        });
        if (response?.result.length > 0) {
          console.log('tỉnh thỏa mãn trong store: ', response?.result)
          try {
            const responseTinh = await mushroom.province.listAsync({
              filters: `id=${response.result[0].provinceId}`
            });
            console.log("Các province thỏa mãn: %o, info: %o", responseTinh);
            if (responseTinh?.result.length > 0) {
              this.idProvince = responseTinh.result[0].id;
            }
          }
          catch (e) {
            console.error("Có lỗi: %o", e);
          }
        }
      }
      catch (e) {
        console.error("Có lỗi: %o", e);
      }

    }
  },
})

